import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
// import '../../assets/css/style2.css';
import axios from 'axios';
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { BiCheck } from 'react-icons/bi';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

class WebsiteDreamerPlan extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lists: [
                {
                    desc: 'The Most Delicious Restaurant',
                    text: 'Business Name'

                },
                {
                    desc: 'Food & Drink',
                    text: 'Primary Category'
                },
                {
                    desc: 'Restaurants',
                    text: 'Specific Service'
                },
                {
                    desc: 'Jamie Terry',
                    text: 'Owner Name'
                }
                ,
                {
                    desc: 'jterry@tmdrestaurant.com',
                    text: 'Email'
                },
                {
                    desc: '215-456-3954',
                    text: 'Phone Number'
                },
                {
                    web: 'www.tmdrestaurant.com',
                    text: 'Website'
                }
            ],
            items: [
                {
                    title: 'When is my Plan ready to use?',
                    desc: 'Your upgraded plan will be ready to use immediately once you complete the payment process.',
                    plus: <AiOutlinePlus />,
                    minus: <AiOutlineMinus />,
                    cardClass: 'mb-3'
                },
                {
                    title: 'Can I upgrade to a higher Plan later?',
                    desc: 'Yes. All businesses start off with a Free account and you can upgrade at anytime.',
                    plus: <AiOutlinePlus />,
                    minus: <AiOutlineMinus />,
                    cardClass: 'mb-3'
                },
                {
                    title: 'How long is the contract term?',
                    desc: 'You are billed month to month. There are no long term contracts on Katika. You can cancel at anytime.',
                    plus: <AiOutlinePlus />,
                    minus: <AiOutlineMinus />,
                    cardClass: 'mb-3'
                },
                {
                    title: 'How do I cancel my subscription?',
                    desc: 'Simply log into your account and go to the Billing section of your account and select "Cancel Plan".',
                    plus: <AiOutlinePlus />,
                    minus: <AiOutlineMinus />,
                    cardClass: 'mb-3'
                },
                {
                    title: 'Do you offer any other Plans?',
                    desc: 'We are currently only offering these plans at this time to provide the best value for businesses.',
                    plus: <AiOutlinePlus />,
                    minus: <AiOutlineMinus />,
                    cardClass: 'mb-3'
                },
            ],
            monthly: [],
            annually: [
                {
                    id: 1,
                    PackageType: 'BASIC',
                    PackageName: 'Personal busines',
                    PackagePrice: 'Free',
                    CurrentPlan: 'Buy',
                    rules: ['Create a free business page', 'Real time page analytics', 'Basic messaging function', 'Photo gallery with API']
                },
                {
                    id: 4,
                    PackageType: 'BUSINESS',
                    PackageName: 'Small business',
                    CurrentPlan: 'Buy',
                    PackagePrice: '$14.99 /year',
                    rules: ['Create targeted campaigns by zipcode', 'Share updates and announcements', 'Use rich media images and videos', 'Trusted secure environment']
                },
                {
                    id: 5,
                    PackageType: 'PRO',
                    PackageName: 'Medium business',
                    CurrentPlan: 'Buy',
                    PackagePrice: '$24.99 /year',
                    rules: ['Create e-brand loyalty club', 'Curate local customer list', 'Use rich media images and videos', 'In-App messaging capabilities']
                }
            ],
            PackageType: 'BASIC',
            PackageName: 'Personal busines',
            PackagePrice: 'Free',
            CurrentPlan: 1,
            Terms: '*Free, forever. No credit cards required.',
            reason: '',
            fromDashboard: this.props.to ? true : false,

        }
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        this.getPlan();

    }

    getPlan = () => {
        var self = this;
        self.setState({
            externalData: false,
        })
        var self = this;
        axios.post(ApiUrl + 'getWebsiteDreamerPlans', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                self.setState({
                    monthly: res.data.monthlyPlan,
                    externalData: true,
                    CurrentPlan: res.data.subscription
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    handleChange() {
        let reason = this.state.reason;
        if (reason != '') {
            console.log(reason)
            axios.post(ApiUrl + 'deactiveBusiness ', { 'deactivate_reason': reason }, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            toast.error('Please select reason');
        }
    }



    render() {
        return (
            <main className="dashboard-page account-page faqs-section">
                {/* Header */}
                {
                    !this.state.fromDashboard && (
                        <DashboardNavbar />
                    )
                }
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    
                    <Row>
                        {
                            !this.state.fromDashboard && (
                                <Col lg={3} md={3} id="sidebar-wrapper">
                                    <Sidebar />
                                </Col>
                            )
                        }
                        
                        <Col lg={this.state.fromDashboard ? 12 : 9} md={this.state.fromDashboard ? 12 : 9} id="page-content-wrapper">

                            <section className="account-page">
                                <div className="y-page-link">
                                    <div className='mb-5 text-center'>
                                        <h1 className='color-black mb-2'>Website Dreamer Pricing</h1>
                                        <h4 className='color-black mb-2'>Providing affordable quality websites</h4>
                                        <h6 className='color-black'>Billed monthly with the ability to cancel at anytime.</h6>
                                    </div>
                                </div>
                            </section>
                            
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                <Tab.Content className='package_plan'>
                                    <Tab.Pane eventKey="first">
                                        <Row>
                                            {this.state.monthly.map((monthly, i) => {
                                                return (
                                                    // <Col lg={4} md={4} className='col-12' key={i}>
                                                        <section className="dashboard-area account-page col-md-4 m-0" key={i}>
                                                            <div className="y-page-link">
                                                                <div className='mb-4'>
                                                                    <h5 className='mb-3'> {  monthly.sub_title  }</h5>
                                                                    <h6>{monthly.description}</h6>
                                                                    {monthly.cost > 0 ? <h2>${monthly.cost} <sub>/{monthly.plan_interval}</sub></h2> : <h2>Free</h2>}
                                                                    <div className="current-pkg">
                                                                        <div className="pkg-box">
                                                                            {this.state.CurrentPlan == monthly.id ?
                                                                                'Your Current Plan'
                                                                                :
                                                                                monthly.status == 'Active' ?
                                                                                    <Link to={`website-dreamer-checkout/${monthly.slug}`}> <div className="bg-FF6435 ">Buy Now</div></Link>
                                                                                    :
                                                                                    'Coming soon'
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ul className="PackageDetails">
                                                                    <h5 className='mb-2'>{monthly.rule_label}</h5>
                                                                    {
                                                                        monthly.rules && monthly.rules.map((rule, k) => {
                                                                            return (
                                                                                <li key={k}>
                                                                                    <span>{rule}</span>
                                                                                    {/* <span
                                                                                        className={`check ${i !== 0 ? 'paid' : ''}`}
                                                                                        style={{ marginRight: '8px' }}
                                                                                        dangerouslySetInnerHTML={{ __html: website.icon.svg }}
                                                                                    />
                                                                                    <span>{website.icon.label}</span> */}
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>
                                                                {/* <ul className="PackageDetails">
                                                                    <h5 className='mb-2'>Marketing</h5>
                                                                    {
                                                                        monthly.rules_marketing && monthly.rules_marketing.map((marketing, k) => {
                                                                            return (
                                                                                <li key={k}>
                                                                                    <span
                                                                                        className={`check ${i !== 0 ? 'paid' : ''}`}
                                                                                        style={{ marginRight: '8px' }}
                                                                                        dangerouslySetInnerHTML={{ __html: marketing.icon.svg }}
                                                                                    />
                                                                                    <span>{marketing.icon.label}</span>
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul> */}
                                                            </div>
                                                        </section>
                                                    // </Col>
                                                )
                                            })}
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>

                    </Row>
                    <ToastContainer />
                </Container>
                {
                    !this.state.fromDashboard && (
                        <div className="margin-top-50px">
                            {/* Footer */}
                            <Footer />
                        </div>
                    )
                }
            </main >




        );
    }
}

export default WebsiteDreamerPlan;