import React, { Component } from 'react';
import DashboardNavbar from "../../components/user/DashboardNavbar";
import Sidebar from "../../components/user/sidebar";
import Footer from "../../components/common/footer/Footer";
import { Container, Row, Col } from "react-bootstrap";
// import '../../assets/css/style2.css';
import axios from 'axios';
import { ApiUrl } from '../../config';
import { uToken } from '../../useToken';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { BiCheck } from 'react-icons/bi';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { Collapse, CardBody, Card } from 'reactstrap';
import Button from 'react-bootstrap/Button';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import LoadingModal from '../../components/generic/LoadingModal';

class Domain extends Component {

    constructor(props) {
        super(props)
        this.state = {
            // lists: [
            //     {
            //         desc: 'The Most Delicious Restaurant',
            //         text: 'Business Name'

            //     },
            //     {
            //         desc: 'Food & Drink',
            //         text: 'Primary Category'
            //     },
            //     {
            //         desc: 'Restaurants',
            //         text: 'Specific Service'
            //     },
            //     {
            //         desc: 'Jamie Terry',
            //         text: 'Owner Name'
            //     }
            //     ,
            //     {
            //         desc: 'jterry@tmdrestaurant.com',
            //         text: 'Email'
            //     },
            //     {
            //         desc: '215-456-3954',
            //         text: 'Phone Number'
            //     },
            //     {
            //         web: 'www.tmdrestaurant.com',
            //         text: 'Website'
            //     }
            // ],
            // items: [
            //     {
            //         title: 'When is my Plan ready to use?',
            //         desc: 'Your upgraded plan will be ready to use immediately once you complete the payment process.',
            //         plus: <AiOutlinePlus />,
            //         minus: <AiOutlineMinus />,
            //         cardClass: 'mb-3'
            //     },
            //     {
            //         title: 'Can I upgrade to a higher Plan later?',
            //         desc: 'Yes. All businesses start off with a Free account and you can upgrade at anytime.',
            //         plus: <AiOutlinePlus />,
            //         minus: <AiOutlineMinus />,
            //         cardClass: 'mb-3'
            //     },
            //     {
            //         title: 'How long is the contract term?',
            //         desc: 'You are billed month to month. There are no long term contracts on Katika. You can cancel at anytime.',
            //         plus: <AiOutlinePlus />,
            //         minus: <AiOutlineMinus />,
            //         cardClass: 'mb-3'
            //     },
            //     {
            //         title: 'How do I cancel my subscription?',
            //         desc: 'Simply log into your account and go to the Billing section of your account and select "Cancel Plan".',
            //         plus: <AiOutlinePlus />,
            //         minus: <AiOutlineMinus />,
            //         cardClass: 'mb-3'
            //     },
            //     {
            //         title: 'Do you offer any other Plans?',
            //         desc: 'We are currently only offering these plans at this time to provide the best value for businesses.',
            //         plus: <AiOutlinePlus />,
            //         minus: <AiOutlineMinus />,
            //         cardClass: 'mb-3'
            //     },
            // ],
            // monthly: [],
            // annually: [
            //     {
            //         id: 1,
            //         PackageType: 'BASIC',
            //         PackageName: 'Personal busines',
            //         PackagePrice: 'Free',
            //         CurrentPlan: 'Buy',
            //         rules: ['Create a free business page', 'Real time page analytics', 'Basic messaging function', 'Photo gallery with API']
            //     },
            //     {
            //         id: 4,
            //         PackageType: 'BUSINESS',
            //         PackageName: 'Small business',
            //         CurrentPlan: 'Buy',
            //         PackagePrice: '$14.99 /year',
            //         rules: ['Create targeted campaigns by zipcode', 'Share updates and announcements', 'Use rich media images and videos', 'Trusted secure environment']
            //     },
            //     {
            //         id: 5,
            //         PackageType: 'PRO',
            //         PackageName: 'Medium business',
            //         CurrentPlan: 'Buy',
            //         PackagePrice: '$24.99 /year',
            //         rules: ['Create e-brand loyalty club', 'Curate local customer list', 'Use rich media images and videos', 'In-App messaging capabilities']
            //     }
            // ],
            isLoadingModal: false,
            isEcommerce: false,
            isWebsiteCreated: false,
            isSubscribed: false,
            PackageType: 'BASIC',
            PackageName: 'Personal busines',
            PackagePrice: 'Free',
            CurrentPlan: 1,
            Terms: '*Free, forever. No credit cards required.',
            reason: '',
            fromDashboard: this.props.to ? true : false,
            

            draftImage: 'https://katika-images.s3.amazonaws.com/category/Artboard%201default_biz1629443022.png',
            
            domainFormData: {
                domainType: '',        // Default selection
                domain_name: '',            // Sub domain value
                own_domain_name: '',               // Custom domain value
                own_domain_registrar: '',       // Domain registrar value
                own_domain_status: '',       // Domain registrar value
                status: '',       // Domain registrar value
                website_builder_main_domain: ''       // Website type
            }
            

        }
        // this.handleChange = this.handleChange.bind(this);
        this.handleChangeDomainType = this.handleChangeDomainType.bind(this);

    }

    // begin loading modal

        handleClose = () => {
            const self = this;
            self.setState({
                isLoadingModal: false
            })
        };
        
        modalContent = () => {
            return <div>
                        <h5 className="text-black mb-2">We're building your store, hang tight!</h5>
                    </div>;
                
        } 

        modalFooter = () => { return <></>; }
    // end loading modal


    componentDidMount() {
        this.getWebsite();
    }

    // handleChangeDomainType = (event) => {
    //     // Make sure the event prevents the default behavior correctly
    //     event.preventDefault();
        
    //     const value = event.target.value;
    //     console.log('domain type value = ', value);
    
    //     // Update the state properly with the selected value
    //     this.setState({
    //         domainFormData: {
    //             ...this.state.domainFormData,
    //             domainType: value
    //         }
    //     });
    // };


    // Handle radio button change
    handleChangeDomainType = (event) => {
        const value = event.target.value;
        this.setState(prevState => ({
            domainFormData: {
                ...prevState.domainFormData,
                domainType: value
            }
        }));
    };

    // Handle text input change (domain_name, domain, etc.)
    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            domainFormData: {
                ...prevState.domainFormData,
                [name]: value
            }
        }));
    };
    

    getWebsite = () => {
        var self = this;
        self.setState({
            externalData: false,
        })
        var self = this;
        axios.post(ApiUrl + 'getWebsite', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
        .then(function (res) {
            // self.setState({
            //     monthly: res.data.monthlyPlan,
            //     annually: res.data.annualyPlan,
            //     externalData: true,
            //     CurrentPlan: res.data.subscription
            // })




            
            self.setState({
                domainFormData: res.data.userDomain,
                isWebsiteCreated: res.data.isWebsiteCreated,
                isEcommerce: res.data.isEcommerce,
                isSubscribed: res.data.isSubscribed,
                // template1: res.data.template1,
                // template2: res.data.template2,
                // template3: res.data.template3,
                // annually: res.data.annualyPlan,
                // externalData: true,
                // CurrentPlan: res.data.subscription
            })
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    requestOwnDomain = () => {
        var self = this;
        self.setState({
            externalData: false,
        });
        
        if(this.state.domainFormData.domain_name == '' && this.state.domainFormData.own_domain_name == '') {            
            toast.error("Domain name is required.");
            return;
        }

        if(this.state.domainFormData.domainType == 'sub') {
            
            if(this.state.domainFormData.domain_name != '' || this.state.domainFormData.domain_name != null) {            
                if(!/^[a-zA-Z0-9_\-]+$/.test(this.state.domainFormData.domain_name)){
                    toast.error("Default domain name can only contain letters, numbers, underscores and dashes.");
                    return;
                }
            }
        } else {
            
            if(this.state.domainFormData.own_domain_name != '' || this.state.domainFormData.domain_name != null) {
                if(!/^[a-zA-Z0-9_.\-]+$/.test(this.state.domainFormData.own_domain_name)){
                    toast.error("Own domain name can only contain letters, numbers, underscores and dashes.");
                    return;
                }
                if(this.state.domainFormData.own_domain_registrar == '' || this.state.domainFormData.own_domain_registrar == null) {          
                    toast.error("Domain registrar is required.");
                    return;
                }
            }
        }


        
        axios.post(ApiUrl + 'requestOwnDomain', {
            'domain_type': this.state.domainFormData.domainType,
            'domain_name': this.state.domainFormData.domain_name,
            'own_domain_name': this.state.domainFormData.own_domain_name,
            'own_domain_registrar': this.state.domainFormData.own_domain_registrar
        }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
        .then(function (res) {
            // console.log("requestOwnDomain res = ", res);
            if (res.data.success === true) {
                self.setState(prevState => ({
                    domainFormData: {
                        ...prevState.domainFormData,
                        own_domain_status: res.data.own_domain_status
                    }
                }));
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    // begin create store
    createStore = () => {
        var self = this;
        self.setState({
            externalData: false,
            isLoadingModal: true
        })
        
        if(this.state.domainFormData.domain_name == '' && this.state.domainFormData.own_domain_name == '') {            
            toast.error("Domain name is required.");
            return;
        }

        // if(this.state.domainFormData.domainType == 'sub') {
            
            if(this.state.domainFormData.domain_name != '' || this.state.domainFormData.domain_name != null) {            
                if(!/^[a-zA-Z0-9_\-]+$/.test(this.state.domainFormData.domain_name)){
                    toast.error("Default domain name can only contain letters, numbers, underscores and dashes.");
                    return;
                }
            }
        // } else {
            
        //     if(this.state.domainFormData.own_domain_name != '' || this.state.domainFormData.domain_name != null) {
        //         if(!/^[a-zA-Z0-9_.\-]+$/.test(this.state.domainFormData.own_domain_name)){
        //             toast.error("Own domain name can only contain letters, numbers, underscores and dashes.");
        //             return;
        //         }
        //         if(this.state.domainFormData.own_domain_registrar == '' || this.state.domainFormData.own_domain_registrar == null) {          
        //             toast.error("Domain registrar is required.");
        //             return;
        //         }
        //     }
        // }


        
        axios.post(ApiUrl + 'websiteBuilderCreateStore', {
            'domain_type': this.state.domainFormData.domainType,
            'domain_name': this.state.domainFormData.domain_name,
            // 'own_domain_name': this.state.domainFormData.own_domain_name,
            // 'own_domain_registrar': this.state.domainFormData.own_domain_registrar
        }, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
        .then(function (res) {
            // console.log("requestOwnDomain res = ", res);
            if (res.data.success === true) {
                window.location.href = res.data.url;
                // console.log(res.data.url);
                // self.setState(prevState => ({
                //     domainFormData: {
                //         ...prevState.domainFormData,
                //         own_domain_status: res.data.own_domain_status
                //     }
                // }));
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
            self.setState({
                isLoadingModal: false
            })
        })
        .catch(function (error) {
            // console.log(error);
        });
    }
    // end create store

    // handleChange() {
    //     let reason = this.state.reason;
    //     if (reason != '') {
    //         console.log(reason)
    //         axios.post(ApiUrl + 'deactiveBusiness ', { 'deactivate_reason': reason }, {
    //             headers: {
    //                 'Authorization': `Bearer ${uToken()}`
    //             }
    //         })
    //             .then(function (res) {
    //                 if (res.data.success === true) {
    //                     toast.success(res.data.message);
    //                 } else {
    //                     toast.error(res.data.message);
    //                 }
    //             })
    //             .catch(function (error) {
    //                 console.log(error);
    //             });
    //     } else {
    //         toast.error('Please select reason');
    //     }
    // }



    render() {
        return (
            <main className="dashboard-page account-page faqs-section">
                {/* Header */}
                {
                    !this.state.fromDashboard && (
                        <DashboardNavbar />
                    )
                }
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}

                <Container>
                    <Row>
                        {
                            !this.state.fromDashboard && (
                                <Col lg={3} md={3} id="sidebar-wrapper">
                                    <Sidebar />
                                </Col>
                            )
                        }
                        <Col lg={this.state.fromDashboard ? 12 : 9} md={this.state.fromDashboard ? 12 : 9} id="page-content-wrapper">
                            <section className="dashboard-area mx-0 business-detail">
                                <div className="y-page-link">
                                    <h2>Domain</h2>
                                    <Row>
                                        <Col lg={12} md={12} className='col-12'>
                                            <Card className="card-custom-1">
                                                <CardBody>
                                                    <AvForm >
                                                        <div className="PopupContent AddDomain">
                                                            {/* <h2>Domain Setting</h2> */}
                                                            {
                                                                this.state.isSubscribed && this.state.isWebsiteCreated ? (
                                                                    <>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <AvRadioGroup
                                                                                    value={this.state.domainFormData.domainType}  // Controlled value
                                                                                    onChange={this.handleChangeDomainType}
                                                                                    inline
                                                                                    name="domain_type"
                                                                                    label=""
                                                                                    required
                                                                                    errorMessage="Pick one!"
                                                                                >
                                                                                    <AvRadio customInput label="Default domain" value="sub" />
                                                                                    <AvRadio customInput label="Add your own domain" value="own" />
                                                                                </AvRadioGroup>
                                                                            </div>

                                                                            {this.state.domainFormData.domainType === 'sub' && (
                                                                                <>
                                                                                    <div className="d-flex align-items-center mb-2">
                                                                                        <div className="col-md-10" style={{ paddingRight: 0 }}>
                                                                                            <AvGroup>
                                                                                                <AvInput
                                                                                                    type="text"
                                                                                                    name="domain_name"
                                                                                                    value={this.state.domainFormData.domain_name}
                                                                                                    onChange={this.handleInputChange}
                                                                                                    placeholder="example"
                                                                                                    required
                                                                                                    pattern="^[a-zA-Z0-9_\-]+$"
                                                                                                    errorMessage="Domain name should not contain special characters except _ and -"
                                                                                                />
                                                                                                <AvFeedback>Required Field *</AvFeedback>
                                                                                            </AvGroup>
                                                                                        </div>
                                                                                        <div className="col-md-2" style={{ paddingLeft: 0 }}>
                                                                                            <p className="DomainName">{this.state.domainFormData.website_builder_main_domain}</p>

                                                                                            {/* {
                                                                                                this.state.domainFormData.type === 'Store' ? (
                                                                                                    <p className="DomainName">.katika.us</p>
                                                                                                ) : (
                                                                                                    <p className="DomainName">.katika.us</p>
                                                                                                )
                                                                                            } */}
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )}

                                                                            {this.state.domainFormData.domainType === 'own' && (
                                                                                <Row>
                                                                                    <Col lg={6} md={6} className='col-12'>
                                                                                        <AvGroup>
                                                                                            <AvInput
                                                                                                type="text"
                                                                                                name="own_domain_name"
                                                                                                value={this.state.domainFormData.own_domain_name}
                                                                                                onChange={this.handleInputChange}
                                                                                                placeholder="www.example.com"
                                                                                                required
                                                                                            />
                                                                                            <AvFeedback>Required Field *</AvFeedback>
                                                                                        </AvGroup>
                                                                                        <p>Note: Please make sure to have either an A record pointing to 67.225.137.81</p>
                                                                                    </Col>
                                                                                    <Col lg={6} md={6} className='col-12'>
                                                                                        <p>Help</p>
                                                                                        <div>
                                                                                            <small>Step 1 You must setup an NS "A" record to our server IP 67.225.137.81</small>
                                                                                        </div>
                                                                                        <div>
                                                                                            <small>Step 2 After completing step 1, please submit to admin for final configuration.</small>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            )}

                                                                            {this.state.domainFormData.domainType === 'own' && (
                                                                                <div id="website_domain_main2">
                                                                                    <p className="w-100">What is your domain registrar?</p>
                                                                                    <small>I.e. where did you purchase your domain? GoDaddy, Google, Siteground, etc.</small>
                                                                                </div>
                                                                            )}

                                                                            {this.state.domainFormData.domainType === 'own' && (
                                                                                <div className="col-md-12" id="website_domain_main3">
                                                                                    <AvGroup>
                                                                                        <AvInput
                                                                                            type="text"
                                                                                            name="own_domain_registrar"
                                                                                            value={this.state.domainFormData.own_domain_registrar}
                                                                                            onChange={this.handleInputChange}
                                                                                            placeholder="GoDaddy"
                                                                                            required
                                                                                        />
                                                                                        <AvFeedback>Required Field *</AvFeedback>
                                                                                    </AvGroup>
                                                                                </div>
                                                                            )}

                                                                            <p className="text-danger" id="website_sub_domain_validation_error" style={{ display: 'none' }}>Required a valid Sub domain</p>
                                                                            <p className="text-danger" id="website_domain_validation_error" style={{ display: 'none' }}>Domain is required</p>
                                                                            <p className="text-danger" id="website_domain_registrar_validation_error" style={{ display: 'none' }}>Domain registrar is required</p>
                                                                        </div>

                                                                        <div className="BottomMessage">
                                                                            <p>By continuing, you agree to Katika’s terms and conditions.</p>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {
                                                                            this.state.isEcommerce ? (
                                                                                <>
                                                                                    {/* <h2>Ecommerce</h2> */}
                                                                                    <div className="row">
                                                                                        <div className="col-lg-12">
                                                                                            <AvRadioGroup
                                                                                                value={this.state.domainFormData.domainType}  // Controlled value
                                                                                                onChange={this.handleChangeDomainType}
                                                                                                inline
                                                                                                name="domain_type"
                                                                                                label=""
                                                                                                required
                                                                                                errorMessage="Pick one!"
                                                                                            >
                                                                                                <AvRadio customInput label="Default domain" value="sub" />
                                                                                                {/* <AvRadio customInput label="Add your own domain" value="own" /> */}
                                                                                            </AvRadioGroup>
                                                                                        </div>

                                                                                        {this.state.domainFormData.domainType === 'sub' && (
                                                                                            <>
                                                                                                <div className="d-flex align-items-center mb-2">
                                                                                                    <div className="col-md-10" style={{ paddingRight: 0 }}>
                                                                                                        <AvGroup>
                                                                                                            <AvInput
                                                                                                                type="text"
                                                                                                                name="domain_name"
                                                                                                                value={this.state.domainFormData.domain_name}
                                                                                                                onChange={this.handleInputChange}
                                                                                                                placeholder="example"
                                                                                                                required
                                                                                                                pattern="^[a-zA-Z0-9_\-]+$"
                                                                                                                errorMessage="Domain name should not contain special characters except _ and -"
                                                                                                            />
                                                                                                            <AvFeedback>Required Field *</AvFeedback>
                                                                                                        </AvGroup>
                                                                                                    </div>
                                                                                                    <div className="col-md-2" style={{ paddingLeft: 0 }}>
                                                                                                        <p className="DomainName">{this.state.domainFormData.website_builder_main_domain}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )}

                                                                                        

                                                                                        <p className="text-danger" id="website_sub_domain_validation_error" style={{ display: 'none' }}>Required a valid Sub domain</p>
                                                                                        <p className="text-danger" id="website_domain_validation_error" style={{ display: 'none' }}>Domain is required</p>
                                                                                        <p className="text-danger" id="website_domain_registrar_validation_error" style={{ display: 'none' }}>Domain registrar is required</p>
                                                                                    </div>

                                                                                    <div className="BottomMessage">
                                                                                        <p>By continuing, you agree to Katika’s terms and conditions.</p>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-lg-12">
                                                                                            <AvRadioGroup
                                                                                                value={this.state.domainFormData.domainType}  // Controlled value
                                                                                                onChange={this.handleChangeDomainType}
                                                                                                inline
                                                                                                name="domain_type"
                                                                                                label=""
                                                                                                required
                                                                                                errorMessage="Pick one!"
                                                                                            >
                                                                                                <AvRadio customInput label="Default domain" value="sub" />
                                                                                                <AvRadio customInput label="Add your own domain" value="own" />
                                                                                            </AvRadioGroup>
                                                                                        </div>

                                                                                        {this.state.domainFormData.domainType === 'sub' && (
                                                                                            <>
                                                                                                <div className="d-flex align-items-center mb-2">
                                                                                                    <div className="col-md-10" style={{ paddingRight: 0 }}>
                                                                                                        <AvGroup>
                                                                                                            <AvInput
                                                                                                                type="text"
                                                                                                                name="domain_name"
                                                                                                                value={this.state.domainFormData.domain_name}
                                                                                                                onChange={this.handleInputChange}
                                                                                                                placeholder="example"
                                                                                                                required
                                                                                                                pattern="^[a-zA-Z0-9_\-]+$"
                                                                                                                errorMessage="Domain name should not contain special characters except _ and -"
                                                                                                            />
                                                                                                            <AvFeedback>Required Field *</AvFeedback>
                                                                                                        </AvGroup>
                                                                                                    </div>
                                                                                                    <div className="col-md-2" style={{ paddingLeft: 0 }}>
                                                                                                        <p className="DomainName">{this.state.domainFormData.website_builder_main_domain}</p>

                                                                                                        {/* {
                                                                                                            this.state.domainFormData.type === 'Store' ? (
                                                                                                                <p className="DomainName">.katika.us</p>
                                                                                                            ) : (
                                                                                                                <p className="DomainName">.katika.us</p>
                                                                                                            )
                                                                                                        } */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )}

                                                                                        {this.state.domainFormData.domainType === 'own' && (
                                                                                            <Row>
                                                                                                <Col lg={6} md={6} className='col-12'>
                                                                                                    <AvGroup>
                                                                                                        <AvInput
                                                                                                            type="text"
                                                                                                            name="own_domain_name"
                                                                                                            value={this.state.domainFormData.own_domain_name}
                                                                                                            onChange={this.handleInputChange}
                                                                                                            placeholder="www.example.com"
                                                                                                            required
                                                                                                        />
                                                                                                        <AvFeedback>Required Field *</AvFeedback>
                                                                                                    </AvGroup>
                                                                                                    <p>Note: Please make sure to have either an A record pointing to 67.225.137.81</p>
                                                                                                </Col>
                                                                                                <Col lg={6} md={6} className='col-12'>
                                                                                                    <p>Help</p>
                                                                                                    <div>
                                                                                                        <small>Step 1 You must setup an NS "A" record to our server IP 67.225.137.81</small>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <small>Step 2 After completing step 1, please submit to admin for final configuration.</small>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        )}

                                                                                        {this.state.domainFormData.domainType === 'own' && (
                                                                                            <div id="website_domain_main2">
                                                                                                <p className="w-100">What is your domain registrar?</p>
                                                                                                <small>I.e. where did you purchase your domain? GoDaddy, Google, Siteground, etc.</small>
                                                                                            </div>
                                                                                        )}

                                                                                        {this.state.domainFormData.domainType === 'own' && (
                                                                                            <div className="col-md-12" id="website_domain_main3">
                                                                                                <AvGroup>
                                                                                                    <AvInput
                                                                                                        type="text"
                                                                                                        name="own_domain_registrar"
                                                                                                        value={this.state.domainFormData.own_domain_registrar}
                                                                                                        onChange={this.handleInputChange}
                                                                                                        placeholder="GoDaddy"
                                                                                                        required
                                                                                                    />
                                                                                                    <AvFeedback>Required Field *</AvFeedback>
                                                                                                </AvGroup>
                                                                                            </div>
                                                                                        )}

                                                                                        <p className="text-danger" id="website_sub_domain_validation_error" style={{ display: 'none' }}>Required a valid Sub domain</p>
                                                                                        <p className="text-danger" id="website_domain_validation_error" style={{ display: 'none' }}>Domain is required</p>
                                                                                        <p className="text-danger" id="website_domain_registrar_validation_error" style={{ display: 'none' }}>Domain registrar is required</p>
                                                                                    </div>

                                                                                    <div className="BottomMessage">
                                                                                        <p>By continuing, you agree to Katika’s terms and conditions.</p>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                            
                                                            <>
                                                                {
                                                                    this.state.isWebsiteCreated ? (
                                                                        <>
                                                                            {
                                                                                this.state.domainFormData.domainType === 'own' && (
                                                                                    <>
                                                                                        {
                                                                                            this.state.domainFormData.own_domain_status == 'Inactive' && (
                                                                                                <div className="button-modal-footer">
                                                                                                    <Button variant="secondary">Close</Button>
                                                                                                    <Button onClick={() => this.requestOwnDomain()} variant="primary">Save Changes</Button>
                                                                                                </div>                                                                    
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            this.state.domainFormData.own_domain_status == 'Pending' && (
                                                                                                <div className="BottomMessage">
                                                                                                    <p className='text-danger'>Your own domain request is under review</p>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }

                                                                            {
                                                                                this.state.domainFormData.domainType === 'sub' && (
                                                                                    <>
                                                                                        {
                                                                                            this.state.domainFormData.own_domain_status != 'Active' && (
                                                                                                <div className="button-modal-footer">
                                                                                                    <Button variant="secondary">Close</Button>
                                                                                                    <Button onClick={() => this.requestOwnDomain()} variant="primary">Save Changes</Button>
                                                                                                </div>                                                                    
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                this.state.isEcommerce ? (
                                                                                    <div className="button-modal-footer">
                                                                                        <Button variant="secondary">Close</Button>
                                                                                        <Button onClick={() => this.createStore()} variant="primary">Create my online store</Button>
                                                                                    </div>     
                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            
                                                            </>

                                                        </div>
                                                    </AvForm>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                        <Nav variant="pills" className="flex-column">
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="first">Billed Monthly</Nav.Link>
                                                <Nav.Link eventKey="second">Billed Annually</Nav.Link>
                                            </Nav.Item> */}

                                        </Nav>

                                        <Tab.Content className='package_plan'>
                                            <Tab.Pane eventKey="first">
                                                <Row>
                                                    {/* {this.state.monthly.map((monthly, i) => {
                                                        return (
                                                            <Col lg={4} md={4} className='col-12' key={i}>
                                                                <section className="dashboard-area mx-0 account-page">
                                                                    <div className="y-page-link">
                                                                        <div className='border-bootom'>
                                                                            <h6>{monthly.description}</h6>
                                                                            <h5> {  monthly.sub_title  }</h5>
                                                                            {monthly.cost > 0 ? <h2>${monthly.cost} <sub>/{monthly.plan_interval}</sub></h2> : <h2>Free</h2>}
                                                                            
                                                                            <div className="current-pkg">
                                                                                <div className="pkg-box">
                                                                                    {this.state.CurrentPlan == monthly.id ?
                                                                                        'Your Current Plan'
                                                                                        :
                                                                                        i == 0 ?
                                                                                            <Link to={`#`} className='disabled'> <div className="bg-FF6435 ">Buy Now</div></Link>
                                                                                            :
                                                                                            <Link to={`checkout/${monthly.slug}`}> <div className="bg-FF6435 ">Buy Now</div></Link>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ul className="PackageDetails">
                                                                            {
                                                                                monthly.rules && monthly.rules.map((rule, k) => {
                                                                                    return (
                                                                                        <li key={k}><span className={`check ${i != 0 ? 'paid' : ''}`}><BiCheck /></span>{rule}</li>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </ul>
                                                                        {i == 0 ? <div className="terms">{this.state.Terms}</div> : ""}
                                                                    </div>
                                                                </section>
                                                            </Col>
                                                        )
                                                    })} */}
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <Row>
                                                    {/* {this.state.annually.map((monthly, i) => {
                                                        return (
                                                            <Col lg={4} md={4} className='col-12' key={i}>
                                                                <section className="dashboard-area mx-0 account-page">
                                                                    <div className="y-page-link">
                                                                        <div className='border-bootom'>
                                                                            <h6>{monthly.description}</h6>
                                                                            <h5> {  monthly.sub_title  }
                                                                            </h5>
                                                                            {monthly.cost > 0 ? <h2>${monthly.cost} <sub>/{monthly.plan_interval}</sub></h2> : <h2>Free</h2>}
                                                                            <div className="current-pkg">
                                                                                <div className="pkg-box">
                                                                                    {this.state.CurrentPlan == monthly.id ?
                                                                                        'Your Current Plan'
                                                                                        :
                                                                                        i == 0 ?
                                                                                            <Link to={`#`} className='disabled'> <div className="bg-FF6435 ">Buy Now</div></Link>
                                                                                            :
                                                                                            <Link to={`checkout/${monthly.slug}`}> <div className="bg-FF6435 ">Buy Now</div></Link>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ul className="PackageDetails">

                                                                            {
                                                                                monthly.rules && monthly.rules.map((rule, k) => {
                                                                                    return (
                                                                                        <li key={k}><span className={`check ${i != 0 ? 'paid' : ''}`}><BiCheck /></span>{rule}</li>
                                                                                    );
                                                                                })

                                                                            }
                                                                        </ul>
                                                                        {i == 0 ? <div className="terms">{this.state.Terms}</div> : ""}
                                                                    </div>
                                                                </section>
                                                            </Col>
                                                        )
                                                    })} */}
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>


                                    </Tab.Container>
                                    {/* {
                                        !this.state.fromDashboard && (
                                            <>
                                            
                                                <div className="FaqsQuestion pt-3 ">
                                                    <div className="SubHeading text-align-center padding-bottom-30px">Get in touch</div>
                                                    <section className="faq-area">
                                                        <Accordion className="accordion accordion-item" id="accordionExample" allowZeroExpanded={true}>

                                                            {this.state.items.map((item, i) => {
                                                                return (
                                                                    <div className={'card ' + item.cardClass} key={i}>
                                                                        <AccordionItem>
                                                                            <AccordionItemHeading className="card-header">
                                                                                <AccordionItemButton className="btn btn-link d-flex align-items-center justify-content-between">
                                                                                    {item.title}
                                                                                    <i className="minus">{item.minus}</i>
                                                                                    <i className="plus">{item.plus}</i>
                                                                                </AccordionItemButton>
                                                                            </AccordionItemHeading>
                                                                            <AccordionItemPanel>
                                                                                <div className="card-body">
                                                                                    {item.desc}
                                                                                </div>
                                                                            </AccordionItemPanel>
                                                                        </AccordionItem>
                                                                    </div>
                                                                )
                                                            })}

                                                        </Accordion>
                                                    </section>
                                                </div>
                                            </>
                                        )
                                    } */}
                                </div>
                            </section>
                        </Col>

                    </Row>
                    {/* begin loading modal */}
                        <LoadingModal
                            show={this.state.isLoadingModal}
                            onHide={this.handleClose}
                            title=""
                            content={this.modalContent()}
                            footer={this.modalFooter()}
                        />
                    {/* end loading modal */}
                    <ToastContainer />
                </Container>
                {
                    !this.state.fromDashboard && (
                        <div className="margin-top-50px">
                            {/* Footer */}
                            <Footer />
                        </div>
                    )
                }
            </main >




        );
    }
}

export default Domain;