import React, { Component } from 'react';
import GeneralHeader from "../../../components/common/GeneralHeader";
import BuyerSidebar from "../../../components/user/public/BuyerSidebar";
import UserInfo from "../../../components/user/public/UserInfo";
import Footer from "../../../components/common/footer/Footer";
import { Container } from "react-bootstrap";
import BookMark from '../../../assets/images/bookmarks.png';
import { FaTrashAlt } from "react-icons/fa";
import { Link , Redirect } from 'react-router-dom';
import axios from 'axios';
import { ApiUrl, frontLaravelUrl } from '../../../config';
import { uToken } from '../../../useToken';
import RButton from 'react-bootstrap/Button'
import NavbarListing from '../../../components/common/NavbarListing';

class BuyerReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 1,
            pageNumber: 1,
            totalpage: "",
            comments: [],
            externalData: false,
            currentPage: 1,
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.handler = this.handler.bind(this);
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    sendRequest = (count) => {
        this.setState({ currentPage: count })
        this.getBusinessList(count);
    }

    componentDidMount() {
        this.getBusinessList();
    }

    getBusinessList = (count) => {
        var self = this;
        let pg = 1;
        if (count != undefined && count != '') {
            pg = count;
        }
        const { id } = this.props.match.params;

        // console.log(data)
        axios.post(ApiUrl + `getBookmarks?page=${pg}&limit=5`, {user_id: id}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(data)
                if (res.data.success == true) {
                    self.setState({
                        externalData: true,
                        comments: res.data.lists,
                        totalpage: res.data.total_pages
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        const { id } = this.props.match.params;
        return (
            <main className="dashboard-page BuyerProfile BuyerReviews BookMarks">
                {/* Header */}
                <NavbarListing handler={this.handler} />
                {/* <GeneralHeader /> */}
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}
                <div className="user-info">
                    <UserInfo userID={id}/>
                </div>
                <Container>
                    <div className="Buyer-Reviews">
                        <div className="sidebarBuyer ReviewsPage">
                            <BuyerSidebar userID={id} />
                        </div>
                        <div className="BuyerContent">
                            <div className="y-page-link reviews">
                                <h4>Bookmarks</h4>
                                <div className="reviews-tabs">
                                    <div className="form-area">
                                        <div className="tabs-content">
                                            <ul className="comments-list padding-top-10px">
                                                {this.state.comments.map((item, i) => {
                                                    return (
                                                        <>
                                                            <li key={i}>
                                                                <div className="comment listing-details">
                                                                    <div className="user-img">
                                                                        <img className="avatar__img" alt="Comment" src={item.business_logo} />
                                                                    </div>
                                                                    <div className="comment-body">
                                                                        <div className="meta-data">
                                                                            <span className="comment__author">
                                                                                {item.name}
                                                                            </span>
                                                                            <p> <a href={`${frontLaravelUrl}/biz/${item.business_slug}`} >{item.business_name}</a> {item.business_address.replace(", USA", "")}</p>
                                                                            <img className="avatar__img" alt="Comment" src={BookMark} />
                                                                        </div>

                                                                    </div>
                                                                    <div className="ReviewsDate">{item.date}</div>
                                                                </div>
                                                            </li>
                                                        </>

                                                    )
                                                })}
                                            </ul>
                                        </div>
                                        <div className="col-lg-12 text-center mt-3">
                                            {
                                                (this.state.totalpage > 1) ?
                                                    Array.from(Array(this.state.totalpage), (e, i) => {
                                                        return (
                                                            <button onClick={() => this.sendRequest(i + 1)} className="theme-btn border-0 mr-2" >
                                                                <span className="">
                                                                    <span className="icon-label">{i + 1}</span></span>
                                                            </button>
                                                        );
                                                    })
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >
        );
    }
}
export default BuyerReviews;