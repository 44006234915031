import React, { Component } from 'react';
import GeneralHeader from "../../components/common/GeneralHeader";
import BuyerSidebar from "../../components/user/BuyerSidebar";
import UserProfileOverview from "../../components/user/UserProfileOverview";
import Footer from "../../components/common/footer/Footer";
import UserInfo from "../../components/user/UserInfo";
import { Container } from "react-bootstrap";
import { uToken } from '../../useToken';
import { ApiUrl, frontLaravelUrl } from '../../config';
import axios from 'axios';
import { Link, Redirect } from "react-router-dom";
import NavbarListing from '../../components/common/NavbarListing';
import Happy from '../../assets/images/happy.png';
import Smile from '../../assets/images/smile.png';
import Review from '../../assets/images/review.png';
import Like from '../../assets/images/like.png';
import $ from 'jquery';

import Rating from 'react-rating';
import Empty from '../../assets/images/empty.png';
import Half from '../../assets/images/half.png';
import Fill from '../../assets/images/fill.png';
import PhotoGallery from '../../components/sliders/ReviewPhotoGallery';
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import Starfilled from '../../assets/images/starfilled.png';
import { AvForm, AvGroup, AvInput, AvFeedback, } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';


class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            profile: '',
            user: '',
            limit: 1,
            pageNumber: 1,
            totalpage: "",
            comments: [],
            currentPage: 1,
            externalData: false,
            WarningAlert: false,
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.handler = this.handler.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onHide() {
        this.setState({ WarningAlert: false });
    }

    handler(e) {
        this.setState({ isSignedUp: true, search: e.search ? e.search : 'all', location: e.location });
    }

    sendRequest = (count) => {
        this.setState({ currentPage: count })
        this.getBusinessList(count);
    }

    componentDidMount() {

        $(document).on('click', '.delete-account-info', function (e) {
            $('body').addClass('modal-open').css({ paddingRight: '17px' });
            $(".account-delete-modal").addClass('show')
            e.preventDefault();
        })
        $(document).on('click', '.account-delete-modal .modal-bg, .account-delete-modal .modal-dialog .btn-box .theme-btn', function (e) {
            $('body').removeClass('modal-open').css({ paddingRight: '0' });
            $(".account-delete-modal").removeClass('show')
            e.preventDefault();
        })

        this.getProfile();
        this.getBusinessList();
    }

    getBusinessList = (count) => {
        var self = this;
        let pg = 1;
        if (count != undefined && count != '') {
            pg = count;
        }

        // console.log(data)
        axios.post(ApiUrl + `getUserReviews?page=${pg}&limit=10`, {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                // console.log(data)
                if (res.data.success == true) {
                    self.setState({
                        externalData: true,
                        comments: res.data.lists,
                        totalpage: res.data.total_pages
                    })
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    getProfile = () => {
        var self = this;
        self.setState({
            externalData: false,
        })

        axios.post(ApiUrl + 'getBuyerProfile', {}, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.user.zip_code == '') {
                    self.setState({
                        WarningAlert: true,
                    })
                }
                self.setState({
                    profile: res.data.profile,
                    user: res.data.user,
                    externalData: true,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleSubmit = async (event, values) => {
        let self = this;
        axios.post(ApiUrl + 'updateZipCode', values, {
            headers: {
                'Authorization': `Bearer ${uToken()}`
            }
        })
            .then(function (res) {
                if (res.data.success === true) {
                    self.setState({
                        WarningAlert: false,
                    })
                    toast.success(res.data.message);
                } else {
                    toast.error(res.data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }




    render() {
        if (this.state.isSignedUp) {
            // redirect to main listing page if user again search
            return <Redirect to={{ pathname: `/listing/${this.state.search.replace(/\s+/g, '-').replace('&', "and").toLowerCase()}/${this.state.location.replace(`, `, '/').replace(/\s+/g, '-').toLowerCase().split("/").reverse().join("/")}`, state: this.state }} />;
        }
        return (
            <main className="dashboard-page BuyerProfile">
                <Helmet>
                    <title>{`${this.state.user.fname} ${this.state.user.lname}'s Profile | ${this.state.user.address}`}</title>
                    <meta name="title" content={`${this.state.user.fname} ${this.state.user.lname}'s Profile | ${this.state.user.address}`} />
                    <meta name="description"
                        content={`Create and share experiences while discovering black businesses in your community.`} />
                    <meta property="og:title" content={`${this.state.user.fname} ${this.state.user.lname}'s Profile | ${this.state.user.address}`} />
                    <meta property="og:description"
                        content={`Create and share experiences while discovering black businesses in your community.`} />
                    <meta property="og:image" content={`${this.state.user.image}`} />

                </Helmet>
                {/* Header */}
                <NavbarListing handler={this.handler} />
                {/* <GeneralHeader /> */}
                {/* <Breadcrumb CurrentPgTitle="Dashboard" MenuPgTitle="pages" img={this.state.breadcrumbimg} /> */}
                <div className="user-info">
                    <UserInfo history={this.props.history} />
                </div>
                <Container>
                    <div className="Buyer-wrapper">
                        <div className="sidebarBuyer">
                            <BuyerSidebar />
                        </div>
                        <div className="BuyerContent">
                            <div className="Buyer-Reviews overView">
                                <div className="BuyerContent">
                                    <div className="y-page-link reviews">
                                        <h4>Reviews</h4>
                                        <div className="reviews-tabs">
                                            <div className="form-area">
                                                <div className="tabs-content">
                                                    <ul className="comments-list padding-top-10px">
                                                        {this.state.comments.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <li key={i}>
                                                                        <div className="comment listing-details" key={i}>
                                                                            <div className="user-img">
                                                                                <img className="avatar__img" alt="Comment" src={item.business_logo} />
                                                                            </div>
                                                                            <div className="comment-body">
                                                                                <div className="meta-data">
                                                                                    <a href={`${frontLaravelUrl}/biz/${item.business_slug}`} className="comment__author">
                                                                                        {item.business_name}
                                                                                    </a>
                                                                                    <p className="comment-user">{item.category} , {item.sub_category}  </p>
                                                                                    <p>{item.business_address.replace(", USA", "")}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="ReviewsDate">{item.date}</div>
                                                                        </div>
                                                                        <div className="rating-rating rating-detail landing">
                                                                            <Rating
                                                                                placeholderRating={item.rating}
                                                                                readonly={true}
                                                                                stop={5}
                                                                                emptySymbol={<img src={Empty} className="icon" />}
                                                                                placeholderSymbol={<img src={Fill} className="icon" />}
                                                                                fullSymbol={<img src={Fill} className="icon" />}
                                                                            />
                                                                            <span className="comment-date">
                                                                                {/* {item.diff} */}
                                                                            </span>
                                                                        </div>
                                                                        <p className="comment-content">
                                                                            {item.description}
                                                                        </p>

                                                                        {item.business_review_image.map((img, i) => {
                                                                            return (
                                                                                <div className="product-img delete-account-info" key={i} onClick={() => this.setState({
                                                                                    currentImg: item.business_review_image[i],
                                                                                    business: item,
                                                                                    all_images: item.business_review_image,
                                                                                    image_click: i,
                                                                                    click: true

                                                                                })}>
                                                                                    <img src={img.image} alt="Photos" />
                                                                                </div>
                                                                            )
                                                                        })}
                                                                        {/* <div className="DeleteReviews">
                                                                    <RButton onClick={() => this.removeReview(i, item.id)}
                                                                        type="button" className="" variant="none">
                                                                        <span><FaTrashAlt /></span>
                                                                    </RButton>
                                                                </div> */}

                                                                    </li>
                                                                </>

                                                            )
                                                        })}
                                                    </ul>
                                                </div>

                                                <div className="col-lg-12 text-center mt-3">
                                                    {
                                                        (this.state.totalpage > 1) ?
                                                            Array.from(Array(this.state.totalpage), (e, i) => {
                                                                return (
                                                                    <button onClick={() => this.sendRequest(i + 1)} className="theme-btn border-0 mr-2" >
                                                                        <span className="">
                                                                            <span className="icon-label">{i + 1}</span></span>
                                                                    </button>
                                                                );
                                                            })
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="buyer-info">
                            <div className="buyer-heading">
                                <h3>About {this.state.user.fname} {this.state.user.lname}</h3>
                            </div>
                            <ul className="VoteReviews">
                                <h6>Review Votes</h6>
                                <li><img src={Happy} alt="Happy" className="card__img" />Cool <b>{this.state.profile.total_cool}</b></li>
                                <li><img src={Smile} alt="Happy" className="card__img" />Funny <b>{this.state.profile.total_funny}</b></li>
                                <li><img src={Review} alt="Happy" className="card__img" />Useful <b>{this.state.profile.total_useful}</b></li>
                                <li><img src={Like} alt="Happy" className="card__img" />Likes <b>{this.state.profile.total_likes}</b></li>
                            </ul>
                            <div className="buyer-content-info">
                                <h4>Member Since</h4>
                                <p>{this.state.user.year}</p>
                            </div>

                            <div className="buyer-content-info">
                                <h4>Birthday</h4>
                                <p>{this.state.user.birthday}</p>
                            </div>

                            <div className="buyer-content-info">
                                <h4>Gender</h4>
                                <p>{this.state.user.gender}</p>
                            </div>
                            <div className="buyer-content-info">
                                <h4>Location</h4>
                                <p>{this.state.user.address}</p>
                            </div>
                            <div className="buyer-content-info">
                                <h4>Things I Love</h4>
                                <p>{this.state.profile.things_i_love}</p>
                            </div>
                            <div className="buyer-content-info">
                                <h4>Best fast food fried chicken</h4>
                                <p>{this.state.profile.fast_food}</p>
                            </div>
                            <div className="buyer-content-info">
                                <h4>Favorite old school artist or group</h4>
                                <p>{this.state.profile.school}</p>
                            </div>
                            <div className="buyer-content-info">
                                <h4>My Favorite Movie</h4>
                                <p>{this.state.profile.movie}</p>
                            </div>
                            <div className="buyer-content-info">
                                <h4>My Favorite Concert</h4>
                                <p>{this.state.profile.concert}</p>
                            </div>
                            <div className="buyer-content-info">
                                <h4>Why You Should Read My Reviews</h4>
                                <p>{this.state.profile.why_reviews}</p>
                            </div>
                        </div>
                    </div>
                </Container>

                <div className="modal-form text-center warning-list ">
                    <div className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                        <Modal show={this.state.WarningAlert} onHide={this.onHide} size="md" backdrop="static">
                            <div className="modal-content warning-list  p-4">
                                <AvForm onValidSubmit={this.handleSubmit}>
                                    <div className="modal-top border-0 mb-4 p-0">
                                        <div className="alert-content">
                                            <div className="form-area">
                                                <div className="tabs-content">
                                                    <div className="EditLocationForm">
                                                        <AvGroup>
                                                            <label>Zipcode</label>
                                                            <AvInput
                                                                placeholder="Enter your Zipcode"
                                                                className="form-control mt-3"
                                                                name="zipcode"
                                                                required
                                                                type="number"
                                                                validate={{ pattern: { value: /(^\d{5}$)|(^\d{5}-\d{4}$)/ } }}
                                                            />
                                                            <AvFeedback>Invalid Input*</AvFeedback>
                                                        </AvGroup>
                                                    </div>
                                                </div>
                                            </div>

                                            <h4 className="modal-title mt-2 mb-1">Please enter your zipcode.</h4>
                                        </div>
                                    </div>
                                    <div className="btn-box">
                                        <button type="submit" className="border-0 button-success mr-1">
                                            Submit
                                        </button>

                                    </div>
                                </AvForm>
                            </div>
                        </Modal>
                    </div>
                </div>

                <div className="modal-form text-center">
                    <div className="modal fade account-delete-modal" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                        <div className="modal-bg"></div>
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content p-4">
                                <div className="modal-top border-0 mb-4 p-0">
                                    <div className="alert-content">
                                        {this.state.click ? <PhotoGallery current={this.state.currentImg} business={this.state.business} images={this.state.all_images} image_click={this.state.image_click} /> : ""}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="margin-top-50px">
                    {/* Footer */}
                    <Footer />
                </div>
            </main >




        );
    }
}

export default Dashboard;