import React, { Component, useRef, useState } from 'react';
import {
    CardElement, Elements, ElementsConsumer, CardNumberElement,
    CardExpiryElement, CardCvcElement, useStripe, useElements
} from '@stripe/react-stripe-js';

import { logEvent, Result, ErrorResult } from '../../utils/util';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import Button from 'react-bootstrap/Button'
import paymentImg from '../../assets/images/paymentImg.png'
import logoImg from '../../assets/images/katika-logo.png'
import paymentProcessingImg from '../../assets/images/payment-processing-loader.gif'
import paymentSuccessImg from '../../assets/images/payment-successful.png'
import paymentFailedImg from '../../assets/images/payment-failed.png'

import { GiChickenOven } from 'react-icons/gi'
import { Link, useHistory } from "react-router-dom";
import Select from 'react-select'
import { BiCheck } from 'react-icons/bi';
import secureConnection from '../../assets/images/secureConnection.svg'
import { toast } from 'react-toastify';
import axios from 'axios';
import { uToken } from '../../useToken';
import { ApiUrl, StripePublishableKey } from '../../config';
import { use } from 'react';
import Modal from 'react-bootstrap/Modal';
import { set } from 'lodash';
import LoadingModal from '../../components/generic/LoadingModal';
// import Website from "./Website";
function CheckoutForm(props) {
    const [promoCode, setPromoCode] = useState();
    // begin loading modal
        const [isPaymentLoading, setPaymentLoading] = useState(false);
        const [isLoadingModal, setIsLoadingModal] = useState(false);
        const [isPaymentProcessCompleted, setIsPaymentProcessCompleted] = useState(false);
        const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

        const handleClose = () => {
            setIsLoadingModal(false);
        };

        const handleSuccessClick = () => {
            // Handle success click (e.g., redirect to the website)
        };

        const modalContent = !isPaymentProcessCompleted ? (
            <div>
                <div className="mb-4">
                    <img src={logoImg} alt="Payment Success" height="40px" width="auto" />
                </div>
                <h5 className="text-black mb-2">Your Payment is Processing</h5>
            </div>
        ) : isPaymentSuccess ? (
            <div>
                {/* <div className="mb-4">
                    <img src={paymentSuccessImg} alt="Payment Success" height="40px" width="auto" />
                </div> */}
                <h5 className="text-black mb-2">Payment Successful</h5>
                <button onClick={() => history.push('/website')} className="btn btn-success mt-3 btn-sm">Go to Website</button>
            </div>
        ) : (
            <div>
                {/* <div className="mb-4">
                    <img src={paymentFailedImg} alt="Payment Failed" height="40px" width="auto" />
                </div> */}
                <h5 className="text-black mb-2">Payment Failed</h5>
                <button onClick={handleClose} className="btn btn-danger mt-3 btn-sm">Close</button>
            </div>
        );

        const modalFooter = isPaymentProcessCompleted && (
            <></>
        );
    // end loading modal
    
    let form = useRef(null); // ref => { current: null }

    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const handleSubmit = async (e, values) => {
        

        // e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setPaymentLoading(true);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
        });

        if (error) {
            toast.error(error.message);
            setPaymentLoading(false);
        }

        if (paymentMethod) {
            setIsLoadingModal(true);
            values.token = paymentMethod;
            values.plan = props.plan.id;
            values.promo_code = promoCode;
            axios.post(ApiUrl + 'subscriptions', values, {
                headers: {
                    'Authorization': `Bearer ${uToken()}`
                }
            })
                .then(function (res) {
                    if (res.data.success === true) {
                        toast.success(res.data.message);
                        setPaymentLoading(false);
                        // window.location.reload();
                        // history.push('/website');
                        setIsPaymentSuccess(true);
                        setIsPaymentProcessCompleted(true);
                    } else {
                        toast.error(res.data.message);
                        setPaymentLoading(false);
                        setIsPaymentSuccess(false);
                        setIsPaymentProcessCompleted(true);

                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setPaymentLoading(false);
                    setIsPaymentSuccess(false);
                    setIsPaymentProcessCompleted(false);
                    setIsLoadingModal(false);
                    
                });
        }

        // if (paymentResult.error) {
        //     alert(paymentResult.error.message);
        // } else {
        //     if (paymentResult.paymentIntent.status === "succeeded") {
        //         alert("Success!");
        //     }
        // }
    };

    const ELEMENT_OPTIONS = {
        style: {
            base: {
                fontSize: '18px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };


    const states = [
        { "label": "Alabama" },
        { "label": "Alaska" },
        { "label": "Arizona" },
        { "label": "Arkansas" },
        { "label": "California" },
        { "label": "Colorado" },
        { "label": "Columbia" },
        { "label": "Connecticut" },
        { "label": "Delaware" },
        { "label": "Florida" },
        { "label": "Georgia" },
        { "label": "Hawaii" },
        { "label": "Idaho" },
        { "label": "Illinois" },
        { "label": "Indiana" },
        { "label": "Iowa" },
        { "label": "Kansas" },
        { "label": "Kentucky" },
        { "label": "Louisiana" },
        { "label": "Maine" },
        { "label": "Maryland" },
        { "label": "Massachusetts" },
        { "label": "Michigan" },
        { "label": "Minnesota" },
        { "label": "Mississippi" },
        { "label": "Missouri" },
        { "label": "Montana" },
        { "label": "Nebraska" },
        { "label": "Nevada" },
        { "label": "New Hampshire" },
        { "label": "New Jersey" },
        { "label": "New Mexico" },
        { "label": "New York" },
        { "label": "North Carolina" },
        { "label": "North Dakota" },
        { "label": "Ohio" },
        { "label": "Oklahoma" },
        { "label": "Oregon" },
        { "label": "Pennsylvania" },
        { "label": "Rhode Island" },
        { "label": "South Carolina" },
        { "label": "South Dakota" },
        { "label": "Tennessee" },
        { "label": "Texas" },
        { "label": "Utah" },
        { "label": "Vermont" },
        { "label": "Virginia" },
        { "label": "Washington" },
        { "label": "West Virginia" },
        { "label": "Wisconsin" },
        { "label": "Wyoming" }
    ];


    return (

        <div className="row">
            <div className="col-lg-8">
                <AvForm
                    role="form"
                    method="post"
                    class="require-validation"
                    data-cc-on-file="false"
                    data-stripe-publishable-key={StripePublishableKey}
                    id="payment-form"
                    // ref={form}
                    ref={(ref) => { form = ref; }}
                    onValidSubmit={handleSubmit}>
                    <div className="billing-form-item">
                        <div className="payment-option">
                            <div className="billing-title-wrap">
                                <h3 className="widget-title pb-0">Payment Method</h3>
                                <div className="title-shape margin-top-10px mb-4"></div>

                                <p className='p-method'>Please select a payment method most convenient to you.</p>
                            </div>
                            <div className="payment-method-wrap p-4">
                                <div className="payment-tab  p-2">
                                    <div className="payment-trigger">
                                        <div className="payment-radio">
                                            <input checked type="radio" name="radio" />
                                            <span className="checkmark"></span>
                                            <span>Pay with Credit Card</span>
                                            <span className="card-icon float-right">
                                                <img src={paymentImg} alt="Payment" />
                                            </span>
                                        </div>
                                        <div className="payment-content payment-active d-block mt-3">

                                            <div className="contact-form-action">
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <div className="input-box">
                                                            <label className="label-text">Card Number</label>
                                                            {/* <AvGroup> */}
                                                            {/* <CardElement /> */}
                                                            <CardNumberElement
                                                                id="cardNumber"
                                                                onBlur={logEvent('blur')}
                                                                onChange={logEvent('change')}
                                                                onFocus={logEvent('focus')}
                                                                onReady={logEvent('ready')}
                                                                options={ELEMENT_OPTIONS}
                                                            />

                                                        </div>
                                                    </div>


                                                    <div className="col-lg-6 col-12">
                                                        <div className="input-box">
                                                            <label className="label-text">Expiration Date</label>
                                                            {/* <AvGroup> */}
                                                            <CardExpiryElement
                                                                id="expiry"
                                                                onBlur={logEvent('blur')}
                                                                onChange={logEvent('change')}
                                                                onFocus={logEvent('focus')}
                                                                onReady={logEvent('ready')}
                                                                options={ELEMENT_OPTIONS}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-12">
                                                        <div className="input-box">
                                                            <label className="label-text">Card Security Code</label>
                                                            {/* <AvGroup> */}
                                                            <CardCvcElement
                                                                id="cvc"
                                                                onBlur={logEvent('blur')}
                                                                onChange={logEvent('change')}
                                                                onFocus={logEvent('focus')}
                                                                onReady={logEvent('ready')}
                                                                options={ELEMENT_OPTIONS}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-12">
                                                        {/* <div className="input-box">
                                                            <a className='cvv' href='#' >What is this</a>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-box mt-4">
                                    <div className="custom-checkbox">
                                        <label htmlFor="chb1" className='secure-note'>
                                            <img className='secure-connection mr-3' src={secureConnection} />
                                            <p className='p-method'>We protect your payment information using encryption to provide bank-level security.</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="billing-form-item">
                        <div className="billing-title-wrap">
                            <h3 className="widget-title pb-0">Billing Address</h3>
                            <div className="title-shape margin-top-10px"></div>
                        </div>
                        <div className="billing-content">
                            <div className="contact-form-action">
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <div className="input-box">
                                            <label className="label-text">First Name</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="First Name"
                                                    className="form-control"
                                                    name="fname"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="input-box">
                                            <label className="label-text">Last Name</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="Last Name"
                                                    className="form-control"
                                                    name="lname"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-12">
                                        <div className="input-box">
                                            <label className="label-text">Email</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="Email"
                                                    className="form-control"
                                                    name="email"
                                                    type="email"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-12">
                                        <div className="input-box">
                                            <label className="label-text">Address</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="Address"
                                                    className="form-control"
                                                    name="address"
                                                    type="textarea"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-12">

                                        <div className="input-box state-checkout-input">
                                            <label className="label-text" style={{zIndex: 999999}}  >State</label>
                                            <Select
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.label}
                                                placeholder='Select State'
                                                options={states}
                                                isMulti={false}
                                                name="state"
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-12">
                                        <div className="input-box">
                                            <label className="label-text">City</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="City"
                                                    className="form-control"
                                                    name="city"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="input-box">
                                            <label className="label-text">ZipCode</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="Zip Code"
                                                    className="form-control"
                                                    name="zipcode"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <div className="input-box">
                                            <label className="label-text">Phone</label>
                                            <AvGroup>
                                                <AvInput
                                                    placeholder="Phone"
                                                    className="form-control"
                                                    name="phone"
                                                    required />
                                                <AvFeedback> Required Field *</AvFeedback>

                                            </AvGroup>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-block-2 mt-4"></div>
                    </div>

                    <div className="btn-box mt-4 purchase text-align-center">
                        <button
                            disabled={isPaymentLoading}
                            type="submit" className="theme-btn border-0 mt-3">
                            {isPaymentLoading ? 'Loading' : 'Complete Purchase'}
                        </button>
                    </div>

                    <div className="btn-box mt-4 guideline-payment text-align-center">
                        <h3>Guaranteed safe checkout</h3>
                        <p> All information is encrypted without risk using a 128-bit SSL (Secure Sockets Layer) protocol. Learn how we protect your data with Stripe.</p>
                    </div>

                </AvForm>

            </div>
            <div className="col-lg-4 col-12">


                <div className="billing-form-item">

                    <div className="billing-title-wrap">
                        <h3 className="widget-title pb-0">Order Summary</h3>
                        <div className="title-shape margin-top-10px"></div>
                    </div>
                    <div className="billing-content">
                        <div className="booking-summary">
                            <ul className="booking-list">
                                <li className="d-flex align-items-center justify-content-between">Small Business Plan: <span>{props.plan.plan_interval}</span></li>
                                <li className="d-flex align-items-center justify-content-between">Subtotal: <span>${props.plan.cost}</span></li>
                                <li className="d-flex align-items-center justify-content-between">Tax: <span> -</span></li>
                            </ul>
                            {/* <div className="section-block-2 mt-4"></div> */}
                            
                            <ul className="booking-list">
                                {/* <li className="d-flex align-items-center justify-content-between">Promo Code: <input type="text" placeholder='Promo Code' /></li> */}
                                <li className="d-flex align-items-center justify-content-between">Promo Code: <input className="w-50" type="text" placeholder='Promo Code' value={promoCode} onChange={(e) => setPromoCode(e.target.value)} /></li>
                            </ul>
                            <div className="section-block-2 mt-4"></div>

                            <ul className="booking-list total-list mt-4">
                                <li className="d-flex align-items-center justify-content-between">Grand Total: <span className="color-text">${props.plan.cost}</span></li>
                            </ul>

                            <div className="coupon-widget mt-4">
                                <div className="contact-form-action">
                                    <div className="btn-box">

                                        <button
                                            onClick={() => form && form.submit()}
                                            disabled={isPaymentLoading}
                                            type="button"
                                            className="theme-btn border-0 mt-3">
                                            {isPaymentLoading ? 'Loading' : 'Complete Purchase'}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-area personal checkout">
                    <ul>
                        <li><span className="check pr-1"><BiCheck /> </span> Your selected Plan will be ready to use immediately.</li>
                        <li><span className="check pr-1"><BiCheck /></span> You will receive an invoice by email with your billing details.</li>
                        <li><span className="check pr-1"><BiCheck /></span> You can upgrade your account at any time.</li>

                    </ul>
                </div>
            </div>
            {/* begin payment status modal */}
                <LoadingModal
                    show={isLoadingModal}
                    onHide={handleClose}
                    title="Payment Process"
                    content={modalContent}
                    footer={modalFooter}
                />
            
            {/* <div className="modal-form text-center warning-list ">
                <div className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel">
                    <Modal show={isLoadingModal} size="md" backdrop="static">
                        <div className="modal-content warning-list  p-4">
                            <div className="modal-top border-0 p-0">
                                <div className="alert-content">
                                    <div className="form-area">
                                        <div className="tabs-content">
                                            <section className="mt-3 mb-3 text-center">
                                                {
                                                    !isPaymentProcessCompleted ?
                                                        <img src={logoImg} height="auto" width="50%" />
                                                    : <></>
                                                }
                                                {
                                                    isPaymentProcessCompleted ?
                                                        <div className="container">
                                                            {
                                                                isPaymentSuccess ?
                                                                    <div>
                                                                        <div className="mb-4">
                                                                            <img src={paymentSuccessImg} alt="" srcset="" height="40px" width="auto"/>
                                                                        </div>
                                                                        <h5 className='text-black mb-2'>Payment Successful</h5>
                                                                        <div className="mt-2">
                                                                            <button onClick={() => history.push('/website')} className="btn btn-success mt-3 btn-sm">Go to Website</button>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <div className="mb-4">
                                                                            <img src={paymentFailedImg} alt="" srcset="" height="40px" width="auto" />
                                                                        </div>
                                                                        <h5 className='text-black mb-2'>Payment Failed</h5>
                                                                        <div className="mt-2">
                                                                            <button onClick={() => setIsLoadingModal(false)} className="btn btn-danger mt-3 btn-sm">Close</button>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                        : 
                                                        <div className="container mt-5">
                                                            <h5>Your Payment is Processing <img src={paymentProcessingImg} alt="" srcset="" /> </h5>
                                                        </div>
                                                }
                                                
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div> */}
        {/* end payment status modal */}
        </div>

    );
}

export default CheckoutForm;
